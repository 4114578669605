/* Regular */

@font-face {
  font-family: 'AnoBold';
  src: url('assets/fonts/AnoBold.woff2') format('woff2'),
    url('assets/fonts/AnoBold.woff') format('woff');
}
@font-face {
  font-family: 'AnoRegular';
  src: url('assets/fonts/AnoRegular.woff2') format('woff2'),
    url('assets/fonts/AnoRegular.woff') format('woff');
}

* {
  font-family: 'AnoRegular';
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#onetrust-banner-sdk.otFloatingRoundedIcon {
  z-index: 110 !important;
}

#onetrust-banner-sdk.otFloatingRoundedIcon.default {
  right: 5em;
  margin: auto;
}

body > div {
  padding-right: 0 !important;
}

iframe {
  z-index: 101 !important;
  display: none;
}
